import { Box, Card, Container, CssBaseline, Grid, ListItem, styled, ThemeProvider, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import QRCode from 'qrcode.react';
import Logo from 'src/components/LogoSign';
import CurrentLottery from 'src/content/dashboards/Lottery/CurrentLottery';
import { useEffect, useState } from 'react';
import { Lottery } from 'src/util/types';
import axios from 'axios';
import config from '../../../config';
import backgroundImg from './lottery-bg2.gif'
import announcementImg from './announcement.gif'
import Marquee from 'react-fast-marquee';
import azg1 from './images/azg1.jpeg'
import azg2 from './images/azg2.jpeg'
import azg3 from './images/azg3.jpeg'
import azg4 from './images/azg4.jpeg'
import azg5 from './images/azg5.jpeg'
import azg6 from './images/azg6.jpeg'
import azg7 from './images/azg7.jpeg'
import azg8 from './images/azg8.jpeg'
import azg9 from './images/azg9.jpeg'
import azg10 from './images/azg10.jpeg'
import azg11 from './images/azg11.jpeg'
import azg12 from './images/azg12.jpeg'
import azg13 from './images/azg13.jpeg'
import azg14 from './images/azg14.jpeg'
import azg15 from './images/azg15.jpeg'
import { Margin } from '@mui/icons-material';

export default function QRCodeDisplayPage() {

  const [lottery, setLottery] = useState<Lottery>();
  const [qrCodeVal, setQrCodeVal] = useState<string>('');

  const fetchQRCodeValue = () => {
    axios.get(config.apiUrl + '/api/lottery/current')
      .then(res => {
        if (res.data.status >= 200) {
          setLottery(res.data.content);
        }
      })
      .catch(err => console.log("Couldn't fetch current Game", err));
  };
  useEffect(() => {
    fetchQRCodeValue();
    // Intervall einrichten
    const interval = setInterval(fetchQRCodeValue, 5000); // 5000ms = 5 Sekunden

    // Bereinigung bei Komponentendemontage
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (lottery) {
      const path = `https://azg-gewinnspiel.de/join/${lottery.id}`;
      setQrCodeVal(path);
    } else {
      setQrCodeVal('');
    }
  }, [lottery]);


  return (
    <>
      <ThemeProvider theme={useTheme}>
        <Container component="main" maxWidth="lg"
          sx={{
            marginTop: '30px'
          }}>
          <Card>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item md={4} xs={12} >


                {qrCodeVal ?
                  <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      maxHeight: '600px',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                  >
                    <Logo />
                    <img src={announcementImg} width='500' />
                    <Typography component="h1" variant="h3">
                      QRCode scannen und teilnehmen
                    </Typography>
                    <Box
                      sx={{
                        marginTop: 3,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Card
                        sx={{
                          padding: '25px'
                        }}
                      >
                        <QRCode value={qrCodeVal} size={256} />

                      </Card>
                    </Box>
                  </Box>
                  :
                  <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      maxHeight: '600px'
                    }}
                  >
                    <Logo />
                    <Typography component="h1" variant="h4"
                      sx={{
                        marginBottom: '15px'
                      }}>
                      Derzeit sind keine Gewinnspiele verfügbar.
                    </Typography>
                  </Box>
                }


              </Grid>

              <Grid item md={8}
                xs={12}>
                <Grid item md={12} xs={12} >
                  {lottery ? <CurrentLottery lotteryData={lottery} /> :
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '30px'

                      }}>
                      <h1>Bewerten Sie Uns</h1>
                      <QRCode value='https://go.ennorev.de/azg' />
                    </Box>

                  }
                </Grid>
              </Grid>
              <Grid item md={12} xs={12} direction='row' >
                <Marquee direction='right' style={{ }}>
                  <Box sx={{paddingRight:'20px'}}><img src={azg1} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg2} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg3} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg4} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg5} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg6} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg15} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg8} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg9} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg10} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg11} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg12} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg13} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg14} width='330' /></Box>
                  <Box sx={{paddingRight:'20px'}}><img src={azg15} width='330' /></Box>
                </Marquee>
              </Grid>
            </Grid>
          </Card>
          <CssBaseline />
        </Container>
      </ThemeProvider >
    </>
  );
}