import {
  Button,
  Card,
  Box,
  Grid,
  Typography,
  useTheme,
  styled,
  Avatar,
  Divider,
  alpha,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar
} from '@mui/material';
import { Alarm } from '@mui/icons-material';
import CountDownTimer from 'src/components/CountDownTimer/CountDownTimer';
import { Lottery } from 'src/util/types';
import { useEffect } from 'react';
import Badge from '@mui/material/Badge';
import PossiblePrizes from 'src/content/pages/external/PossiblePrizes';

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      box-shadow: ${theme.colors.shadows.success};
`
);

const ListItemAvatarWrapper = styled(ListItemAvatar)(
  ({ theme }) => `
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(0.5)};
  border-radius: 60px;
  background: ${theme.palette.mode === 'dark'
      ? theme.colors.alpha.trueWhite[30]
      : alpha(theme.colors.alpha.black[100], 0.07)
    };

  img {
    background: ${theme.colors.alpha.trueWhite[100]};
    padding: ${theme.spacing(0.5)};
    display: block;
    border-radius: inherit;
    height: ${theme.spacing(4.5)};
    width: ${theme.spacing(4.5)};
  }
`
);

function CurrentLottery(props: { lotteryData: Lottery }) {
  const theme = useTheme();

  return (
    <>
      <Grid spacing={0}
        container
      >
        <Grid item xs={12} md={12}>
          <Box p={2}>
            <Box
             display="flex"
             alignItems="center"
             justifyContent="space-around">
              <Typography variant="h1" gutterBottom>
                {props.lotteryData.name}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
              >
                <AvatarSuccess
                  sx={{
                    mr: 2
                  }}
                  variant="rounded"
                >
                  <Alarm fontSize="large" />
                </AvatarSuccess>
                <Box>
                  <Typography variant="h4">{new Date(props.lotteryData.endDate).getDate() ==   new Date(Date.now()).getDate() ? 'Heute' : new Date(props.lotteryData.endDate).toLocaleDateString('de-DE')    }    </Typography>        

                  <Typography variant="subtitle2" noWrap>
                    {new Date(props.lotteryData.endDate).toLocaleTimeString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid md={12} padding={1}
            container
            sx={{
              maxWidth: '100%',
              justifyContent: 'space-around',
              direction: 'horizontal'
            }}
          >
            <CountDownTimer endTime={new Date(props.lotteryData.endDate).getTime() / 1000} />
          </Grid>
          <PossiblePrizes/>
        </Grid>
      </Grid>
    </>
  );
}

export default CurrentLottery;
