import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Lottery, PrizeDto } from 'src/util/types';

import { useState } from 'react'; import axios from 'axios';
import config from 'src/config';
import { useAuth } from 'src/util/authenticationProvider';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';

const formatDate = (value) => {
  return new Date(value).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};


const columns: GridColDef<Lottery[]>[] = [
  {
    field: 'lotteryName',
    headerName: 'Lottery',
    width: 200,
  },
    {
     field: 'participationDate',
     headerName: 'Date',
     width: 170,
     valueFormatter: value =>
       formatDate(value),
   },
  {
    field: 'luckyNumber',
    headerName: 'Lucky Number',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'phone',
    headerName: 'Telefon',
    width: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'winner',
    headerName: 'Win',
    width: 100,
    renderCell: (params) =>
      params.value == true ? <CheckCircle color="success" /> : ''

  },
  {
    field: 'wonItem',
    headerName: 'Prizes',
    width: 250,
    valueGetter: (row: PrizeDto) => {
      return `${row ? row.description : ''}`;
    },
  },
];



export default function ParticipationHistory() {
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<any[]>([]);
  const [rows, setRows] = useState();
  const authContext = useAuth();
const [loading,setLoading]=useState(true)


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  React.useEffect(() => {
    axios.get(config.apiUrl + '/api/lottery/all/participants', {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(response => {
        console.log('res:', response.data);
        setParticipants(response.data.content);
setLoading(false);
      })
      .catch(err => {
        console.log(err)
      })
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
{loading? 
<h1>Loading...</h1>
:

      <DataGrid
        rows={participants}
        columns={columns}
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 100, { value: -1, label: 'All' }]}
        disableMultipleRowSelection
        disableRowSelectionOnClick
      />
    }
    </Box>
  );
}
